import React from 'react';
import styled from 'styled-components';

import { MessageInput, MessageList, useChannelStateContext } from 'stream-chat-react';
import { CustomChannelHeader } from '.';

const ChannelBody = () => {
	const { error } = useChannelStateContext();

	if (error)
		return (
			<ChannelBodyContainer>
				<h1>There is an error loading your chats</h1>
			</ChannelBodyContainer>
		);
	return (
		<ChannelBodyContainer>
			<CustomChannelHeader />
			<MessageInput />
			<MessageList />
		</ChannelBodyContainer>
	);
};

const ChannelBodyContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;

	@media (orientation: landscape) and (max-height: 500px) {
		.str-chat__list {
			height: calc(90vh - 149px) !important;
		}

		.str-chat__input-flat-quoted + .str-chat__list {
			height: calc(90vh - 227px) !important;
		}
	}

	.str-chat {
		background-color: ${(props) => props.theme.white};
	}

	.str-chat__list {
		height: calc(70vh - 149px);

		-ms-overflow-style: none;
		scrollbar-width: none;

		::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}
	}

	.str-chat__reverse-infinite-scroll {
		padding-top: 0px !important;
	}

	.str-chat__send-button {
		display: block !important;
	}

	.str-chat__date-separator {
		padding: 5px;
	}

	.str-chat__input-flat-quoted,
	.str-chat__input-flat {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
	}

	.str-chat__input-flat-quoted + .str-chat__list {
		height: calc(70vh - 227px);
	}

	.str-chat__input-flat .str-chat__textarea > textarea {
		background: transparent !important;
	}

	.str-chat__message--me p {
		color: white;
	}
`;

export default ChannelBody;
