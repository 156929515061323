import React from 'react';
import styled from 'styled-components';

import { RiCloseLine } from 'react-icons/ri';

const NotificationItem = ({ data, handleRemoveNotification }) => {
	return (
		<Container>
			<div className="d-flex justify-content-between align-items-center">
				<NotficationName>{data.title}</NotficationName>
				<Icon color="#4A4A4A" size={17} onClick={() => handleRemoveNotification(data.id)} />
			</div>
			<NotificationDescription>{data.sub_title}</NotificationDescription>
			<NotificationDescription>{data.date}</NotificationDescription>
		</Container>
	);
};

const Container = styled.div`
	background-color: ${(props) => props.theme.white};
	padding: 14px;
	margin-bottom: 15px;
	border-radius: 10px;
`;

const NotficationName = styled.p`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.2px;
	margin: 0 0 2px;
	color: ${(props) => props.theme.primary};

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`;

const NotificationDescription = styled.p`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 2px;
	letter-spacing: 0.2px;
	color: ${(props) => props.theme.text_grey};
`;

const Icon = styled(RiCloseLine)`
	cursor: pointer;
`;

export default NotificationItem;
