import React from "react";
import styled from "styled-components";
import { SubjectTile, Loader } from ".";

const ClassAssignment = ({ data }) => {
	if (!data) return <Loader />;
	return (
		<Container className="flex-wrap">
			{data.map((subject, index) => (
				<SubjectTile key={index} subject={subject} index={index} noImage />
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: space-between;
`;

export default ClassAssignment;
