import { Component } from "react";
import { MathJaxIMG } from "../assets";

class MathJaxOption extends Component {
	onIconClickEvent = () => {
		const { setisMathJaxAdded } = this.props;

		setisMathJaxAdded((prevState) => !prevState);
	};

	render() {
		return (
			<div className="mathjax-icon" onClick={this.onIconClickEvent}>
				<div className="rdw-option-wrapper">
					<img src={MathJaxIMG} alt="MathJax" />
				</div>
			</div>
		);
	}
}

export default MathJaxOption;
