import React from 'react';
import styled from 'styled-components';

import { useChatContext } from 'stream-chat-react';

const NewChannelPreview = ({ user, setShowModal }) => {
	const { client, setActiveChannel } = useChatContext();

	const createChannel = async (otherUserID) => {
		const channel = client.channel('messaging', {
			members: [ client.userID, otherUserID ]
		});
		channel.watch().then(() => {
			setShowModal((prevState) => !prevState);
			setActiveChannel(channel);
		});
	};

	return (
		<Container onClick={() => createChannel(user.id)}>
			<ImageArea>
				{user.image ? (
					<ProfileImage src={user.image} />
				) : (
					<FirstLetter>{user.name && user.name.charAt(0)}</FirstLetter>
				)}
				<ActiveDisplay active={user.online} />
			</ImageArea>
			<div>
				<UserName>{user.name}</UserName>
			</div>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	width: 90%;
	border: none;
	padding: 8px 20px;
	align-items: center;
	background: transparent;
	text-align: left;
	margin: 8px 0;
	color: ${(props) => props.theme.black};
	background: ${(props) => props.theme.white};
	margin: 12px 0;
	border-radius: 10px;

	&:hover {
		cursor: pointer;
	}
`;

const ImageArea = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	background: #2b6cff;
	margin-right: 10px;
	border-radius: 50%;
	position: relative;
`;

const ProfileImage = styled.img`
	height: 100%;
	width: 100%;
	border-radius: 50%;
`;

const UserName = styled.h5`
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.17541px;
	margin: 0 0 2px 0;
	color: ${(props) => props.theme.black};
`;

const FirstLetter = styled.h1`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	text-align: center;
	margin: 0;
	color: ${(props) => props.theme.white};
`;

const ActiveDisplay = styled.div`
	width: 12px;
	height: 12px;
	background-color: ${(props) => (props.active ? '#29ae60' : props.theme.text_grey)};
	border-radius: 50%;
	border: ${(props) => `1px solid ${props.theme.white}`};
	position: absolute;
	left: 74%;
	right: 4%;
	top: 72%;
	bottom: 6%;
`;

export default NewChannelPreview;
