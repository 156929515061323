import axios from "axios";
import { serialize } from "object-to-formdata";
import Swal from "sweetalert2";
import { removeItemsFromStorage } from "..";
import {
	biologyLesson,
	chemistryLesson,
	civicLesson,
	crsLesson,
	englishLesson,
	frenchLesson,
	ictLesson,
	lesson,
	mathLesson,
	physicsLesson,
	scienceLesson,
} from "../images";

const setToStorage = (key, value) => {
	let storedValue = JSON.stringify(value);
	localStorage.setItem(key, storedValue);
};

const getFromStorage = (key) => {
	let value = localStorage.getItem(key);
	return value ? JSON.parse(value) : null;
};

const options = {
	indices: true,
	allowEmptyArrays: true,
};

export const getLessonImage = (subject) => {
	if (subject.toLowerCase().includes("math") || subject.toLowerCase().includes("quantitative")) {
		return mathLesson;
	} else if (subject.toLowerCase().includes("english")) {
		return englishLesson;
	} else if (subject.toLowerCase().includes("religious")) {
		return crsLesson;
	} else if (subject.toLowerCase().includes("french")) {
		return frenchLesson;
	} else if (
		subject.toLowerCase().includes("computer") ||
		subject.toLowerCase().includes("data") ||
		subject.toLowerCase().includes("i.c.t") ||
		subject.toLowerCase().includes("ict")
	) {
		return ictLesson;
	} else if (subject.toLowerCase().includes("civic")) {
		return civicLesson;
	} else if (subject.toLowerCase().includes("biology")) {
		return biologyLesson;
	} else if (subject.toLowerCase().includes("chemistry")) {
		return chemistryLesson;
	} else if (subject.toLowerCase().includes("physics")) {
		return physicsLesson;
	} else if (subject.toLowerCase().includes("science")) {
		return scienceLesson;
	} else {
		return lesson;
	}
};

const makePostAPICall = (data, successCallback, failedCallback) => {
	axios({
		method: "POST",
		url:
			process.env.NODE_ENV === "development"
				? process.env.REACT_APP_LOCAL_BASEURL
				: process.env.REACT_APP_PROD_BASEURL,
		data: serialize(data, options),
		headers: {
			"Content-Type": "multipart/form-data",
			"X-Authorization": getFromStorage("token"),
		},
	})
		.then((response) => {
			if (response.data.status === "invalid") {
				Swal.fire({
					icon: "success",
					toast: true,
					position: "top-end",
					title: "Your session has expired please login again",
					showConfirmButton: false,
					timer: 2500,
				}).then(() => {
					removeItemsFromStorage(["subjects", "studentUser", "currentTerm", "terms", "token"]);
					window.location.reload();
				});
			} else if (response.data.status === "success") {
				successCallback(response.data);
			} else {
				failedCallback(response.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				toast: true,
				position: "top-end",
				title: err.message,
				showConfirmButton: false,
				timer: 2500,
			});
		});
};

const makeGetAPICall = (params, successCallback, failedCallback) => {
	axios({
		method: "GET",
		url:
			process.env.NODE_ENV === "development"
				? process.env.REACT_APP_LOCAL_BASEURL
				: process.env.REACT_APP_PROD_BASEURL,
		params: params,
		headers: {
			"Content-Type": "multipart/form-data",
			"X-Authorization": getFromStorage("token"),
		},
	})
		.then((response) => {
			if (response.data.status === "invalid") {
				Swal.fire({
					icon: "success",
					toast: true,
					position: "top-end",
					title: "Your session has expired please login again",
					showConfirmButton: false,
					timer: 2500,
				}).then(() => {
					removeItemsFromStorage(["subjects", "studentUser", "currentTerm", "terms", "token"]);
					window.location.reload();
				});
			} else if (response.data.status === "success") {
				successCallback(response.data);
			} else {
				failedCallback(response.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				toast: true,
				position: "top-end",
				title: err.message,
				showConfirmButton: false,
				timer: 2500,
			});
		});
};

const getGame = (data) =>
	axios
		.post(
			"https://conduit.9ijakids.com/partner/play-games",
			{ ...data, accessCode: "bJ_lzM1iCApLKXoNSGxSmrZXjX8" },
			{
				headers: {
					secretKey: "tzAZjWeN929",
					accessToken: "bJ_lzM1iCApLKXoNSGxSmrZXjX8",
					"Content-Type": "application/json",
				},
			}
		)
		.then((res) => Promise.resolve(res?.data))
		.catch((error) => Promise.reject(error.message));

const getLeaderBoard = (game_id) =>
	axios
		.get(`https://conduit.9ijakids.com/partner/games-leaderboard/${game_id}`, {
			headers: {
				accessCode: "bJ_lzM1iCApLKXoNSGxSmrZXjX8",
			},
			params: { accessCode: "bJ_lzM1iCApLKXoNSGxSmrZXjX8" },
		})
		.then((res) => Promise.resolve(res?.data?.data))
		.catch((error) => Promise.reject(error.message));

const getGameInfo = (gameData, leaderboardData) =>
	Promise.all([getGame(gameData), getLeaderBoard(leaderboardData)])
		.then((res) => Promise.resolve(res.map((data) => data)))
		.catch((err) => Promise.reject(err.message));

export { makePostAPICall, makeGetAPICall, setToStorage, getFromStorage, getGame, getLeaderBoard, getGameInfo };
