import React from "react";
import styled from "styled-components";
import { FiArrowUpRight } from "react-icons/fi";
import Loader from "./Loader";
import { useAppContext } from "../context/AppContext";
import { Link } from "react-router-dom";

const RecentLessons = ({ data, assignment }) => {
	const { subjects, school } = useAppContext();

	if (!data) return <Loader />;

	return (
		<Container className="flex-wrap">
			{data !== "nothing" ? (
				data.map((lesson, index) => (
					<RecentLessonsContainer
						key={index}
						index={index}
						randomize={!!school?.options}
					>
						<div>
							{assignment ? (
								<>
									<Subject to={`/lessons/${lesson.subject_id}`}>
										{
											subjects?.find(
												(subject) => subject.id === lesson.subject_id
											)["title"]
										}
									</Subject>
									<Title
										to={`/lessons/${lesson.subject_id}?current_lesson=${lesson.lesson_id}`}
									>
										{lesson.topic}
									</Title>
								</>
							) : (
								<>
									<Subject to={`/lessons/${lesson.subject_id}`}>
										{lesson.subject}
									</Subject>
									<Title
										to={`/lessons/${lesson.subject_id}?current_lesson=${lesson.id}`}
									>
										{lesson.title}
									</Title>
								</>
							)}
						</div>
						<FiArrowUpRight size={20} color={"#fff"} />
					</RecentLessonsContainer>
				))
			) : (
				<div className="d-flex col-12 align-items-center justify-content-center">
					<H4>No recent assignments</H4>
				</div>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	max-width: 100%;
	gap: 2%;

	@media only screen and (max-width: 1024px) {
		gap: 2%;
	}

	@media only screen and (max-width: 600px) {
		gap: 10px;
	}
`;

const RecentLessonsContainer = styled.div`
	background-color: ${(props) => {
		const COLORS = ["#F3464A", "#67BB93", "#4699F7"];
		var schoolcolors = [props.theme.primary, props.theme.secondary];
		return props?.randomize
			? schoolcolors[props.index % 2]
			: COLORS[props.index % 3];
	}};
	display: flex;
	padding: 30px 20px;
	align-items: center;
	justify-content: space-between;
	width: 32%;
	border-radius: 20px;
	margin-bottom: 20px;

	& > div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	&:hover {
		color: initial;
		cursor: pointer;
	}

	@media only screen and (max-width: 1024px) {
		width: 47%;
	}

	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`;

const Title = styled(Link)`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 19px;
	letter-spacing: 0.17541px;
	color: ${(props) => props.theme.white};
	margin: 0;
	text-decoration: none;
	display: block;

	@media only screen and (max-width: 1500px) {
		font-size: 12px;
		line-height: 15px;
	}

	&:hover {
		color: initial;
		cursor: pointer;
	}
`;

const Subject = styled(Link)`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 2.17541px;
	text-transform: uppercase;
	color: ${(props) => props.theme.white};
	margin: 0;
	text-decoration: none;

	@media only screen and (max-width: 1500px) {
		letter-spacing: 0.5px;
	}

	&:hover {
		text-decoration: underline;
		cursor: pointer;
		color: initial;
	}
`;

const H4 = styled.h4`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	color: #4a4a4a;
`;

export default RecentLessons;
