import React from "react";
import styled from "styled-components";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { capitalizeFirstLetter } from "../assets";

const FormInput = ({
	showText,
	placeholder,
	value,
	name,
	type,
	icon,
	handleBlur,
	handleChange,
	otherInputStyles,
	otherDivStyles,
	errors,
	touched,
	visible,
	setVisible,
	...remainingProps
}) => {
	if (icon)
		return (
			<div style={{ ...otherDivStyles }}>
				{showText ? <FormText>{capitalizeFirstLetter(name)}</FormText> : null}
				<InputContainer>
					<Icon src={icon} alt="search" />
					<Input
						type="text"
						placeholder={placeholder || ""}
						value={value}
						name={name}
						onBlur={handleBlur}
						onChange={handleChange}
						style={{ ...otherInputStyles }}
						errors={errors}
						touched={touched}
						{...remainingProps}
					/>
				</InputContainer>
				{errors && touched && (
					<FormErrorText>{capitalizeFirstLetter(errors)}</FormErrorText>
				)}
			</div>
		);

	if (name.includes("Password"))
		return (
			<div style={{ ...otherDivStyles }}>
				{showText ? <FormText>{capitalizeFirstLetter(name)}</FormText> : null}
				<InputContainer>
					<Input
						type={visible ? "text" : "password"}
						placeholder={placeholder || ""}
						value={value}
						name={name}
						onBlur={handleBlur}
						onChange={handleChange}
						style={{ paddingLeft: 20, marginRight: 0, ...otherInputStyles }}
						errors={errors}
						touched={touched}
						{...remainingProps}
					/>
					<PasswordIcon
						onClick={() => setVisible((prevVisible) => !prevVisible)}
					>
						{visible ? (
							<AiOutlineEye color="#4A4A4A" size={24} />
						) : (
							<AiOutlineEyeInvisible color="#4A4A4A" size={24} />
						)}
					</PasswordIcon>
				</InputContainer>

				{errors && touched && (
					<FormErrorText>{capitalizeFirstLetter(errors)}</FormErrorText>
				)}
			</div>
		);

	return (
		<div style={{ ...otherDivStyles }}>
			{showText ? <FormText>{capitalizeFirstLetter(name)}</FormText> : null}
			<Input
				type={type}
				placeholder={placeholder || ""}
				value={value}
				name={name}
				onBlur={handleBlur}
				onChange={handleChange}
				style={{ paddingLeft: 20, ...otherInputStyles }}
				errors={errors}
				touched={touched}
				{...remainingProps}
			/>
			{errors && touched && (
				<FormErrorText>{capitalizeFirstLetter(errors)}</FormErrorText>
			)}
		</div>
	);
};

const Input = styled.input`
	border-radius: 5px;
	padding: 9px 30px 9px 45px;
	border: ${(props) =>
		`1px solid ${
			props.errors && props.touched ? props.theme.error : props.theme.grey
		}`};
	color: ${(props) => props.theme.grey};
	margin-right: 15px;

	::placeholder,
	::-webkit-input-placeholder {
		font-family: Inter;
		font-style: normal;
		font-weight: 200;
		font-size: 14px;
		line-height: 20px;
		color: ${(props) => props.theme.text_grey};
	}
	:-ms-input-placeholder {
		font-family: Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}

	@media only screen and (max-width: 750px) {
		width: 165px;
		margin-right: 5px;
	}
`;

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 5px;
	position: relative;
`;

const Icon = styled.img`
	position: absolute;
	width: 15px;
	height: 15px;
	left: 15px;
	z-index: 1;
`;

const PasswordIcon = styled.div`
	position: absolute;
	width: 24px;
	height: 24px;
	right: 15px;
	z-index: 1;
`;

const FormText = styled.p`
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 50px;
	color: ${(props) => props.theme.grey};
	text-align: left;
	margin-bottom: 5px;
`;

const FormErrorText = styled(FormText)`
	font-size: 13px;
	color: ${(props) => props.theme.error};
	font-weight: normal;
	margin: 0;
`;

export default FormInput;
