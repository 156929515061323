import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import FormButton from "./FormButton";
import Preview from "./Preview";

const AssignmentDropzone = ({ setFieldValue, files }) => {
	useEffect(() => {
		return files.forEach((file) => URL.revokeObjectURL(file.preview));
	}, [files]);

	const { getRootProps, getInputProps } = useDropzone({
		noClick: false,
		nodrop: false,
		multiple: false,
		onDrop: (acceptedFiles) => {
			setFieldValue(
				"doc",
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	return (
		<Zone>
			<Upload {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<FormButton text="Upload File" otherStyles={{ marginBottom: 10 }} />
				<p style={{ textAlign: "center" }}>Drag and drop some files here, or click to select files</p>
				<ThumbsContainer>
					{files.map((file) => (
						<Preview file={file} key={file.name} />
					))}
				</ThumbsContainer>
			</Upload>
		</Zone>
	);
};

const Zone = styled.div`
	border: ${(props) => `1px solid ${props.theme.black}`};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 0;
	margin-bottom: 30px;
	border-radius: 5px;
`;

const ThumbsContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-top: 16;
	align-items: flex-start;
	justify-content: center;
`;

const Upload = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export default AssignmentDropzone;
