import React from "react";
import styled from "styled-components";

const AnnouncementTile = ({ announcement }) => {
	return (
		<div className="mb-4">
			<AnnouncementBody>
				<div
					className="notes-area"
					dangerouslySetInnerHTML={{
						__html: announcement,
					}}
				/>
			</AnnouncementBody>
		</div>
	);
};

const AnnouncementBody = styled.h2`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 164.52%;
	color: ${(props) => props.theme.grey};
	margin: 0;
	position: relative;

	&::after {
		content: "";
		width: calc(100%);
		height: 1px;
		background-color: ${(props) => props.theme.primary};
		position: absolute;
		left: 0;
		top: 100%;
	}

	.notes-area {
		background-color: white;
		padding: 20px 0;
		width: 100%;
		min-height: 100%;

		h1 {
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 31px;

			color: #47505b;
		}

		h2 {
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 27px;

			color: #47505b;
		}

		h3 {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 25px;

			color: #47505b;
		}

		h4 {
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 23px;

			color: #47505b;
		}

		p {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;

			color: #5e6c85;
		}

		li {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;

			color: #5e6c85;
		}

		img {
			max-width: 100%;
			width: 50%;
			resize: both;
		}
	}
`;

export default AnnouncementTile;
