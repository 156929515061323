import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getLessonImage } from "../assets/functions";

const SubjectTile = ({ subject }) => {
	return (
		<SubjectContainer>
			<SubjectImage
				src={subject.image ? subject.image : getLessonImage(subject.subject)}
			/>
			<SubjectDescription>
				<Subject to={`/lessons/${subject.subject_id}`}>
					{subject.subject}
				</Subject>
				<SubjectTitle
					to={`/lessons/${subject.subject_id}?current_lesson=${subject.id}`}
				>
					{subject.topic}
				</SubjectTitle>
				<SubjectSubTitle>Week {subject.week}</SubjectSubTitle>
			</SubjectDescription>
		</SubjectContainer>
	);
};

const SubjectContainer = styled.div`
	width: 32%;
	background-color: #fff;
	border-radius: 5px 5px 0px 0px;
	overflow: hidden;
	word-wrap: break-word;

	@media only screen and (max-width: 1024px) {
		width: 49%;
		margin-bottom: 20px;
	}

	@media only screen and (max-width: 600px) {
		width: 100%;
		margin-bottom: 30px;
	}
`;

const SubjectImage = styled.img`
	width: 100%;
	aspect-ratio: 3/1.7;
	object-fit: cover;

	&:hover {
		cursor: pointer;
	}
`;

const SubjectDescription = styled.div`
	padding: 15px;
`;

const Subject = styled(Link)`
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.17541px;
	margin: 0;
	display: block;
	text-decoration: none;
	/* margin-bottom: 3px; */
	color: ${(props) => props.theme.primary};

	@media only screen and (max-width: 1500px) {
		font-size: 12px;
	}

	&:hover {
		color: ${(props) => props.theme.primary};
		text-decoration: underline;
		cursor: pointer;
	}
`;

const SubjectTitle = styled(Link)`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.17541px;
	margin: 0;
	text-decoration: none;
	margin-bottom: 1px;

	color: ${(props) => props.theme.grey};

	@media only screen and (max-width: 1500px) {
		font-size: 14px;
		line-height: 18px;
	}

	&:hover {
		color: ${(props) => props.theme.primary};
		cursor: pointer;
	}
`;

const SubjectSubTitle = styled.h5`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.17541px;
	color: ${(props) => props.theme.text_grey};

	@media only screen and (max-width: 1500px) {
		font-size: 12px;
		line-height: 15px;
	}
`;

export default SubjectTile;
