import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useChannelStateContext, useChatContext } from 'stream-chat-react';
import { GiHamburgerMenu } from 'react-icons/gi';

import { filterObject } from '../assets';
import Moment from 'react-moment';

const CustomChannelHeader = () => {
	const { channel } = useChannelStateContext();
	const { client, openMobileNav, closeMobileNav, navOpen } = useChatContext();

	const [ currentlyChattingWithUser, setCurrentlyChattingWithUser ] = useState({});

	useEffect(
		() => {
			const user = filterObject(channel.state.members, 'user_id', client.userID);
			setCurrentlyChattingWithUser(user[Object.keys(user)[0]].user);
		},
		[ channel, client ]
	);

	return (
		<Header>
			<BurgerMenu
				onClick={() => {
					return navOpen ? closeMobileNav() : openMobileNav();
				}}
			>
				<GiHamburgerMenu size={22} />
			</BurgerMenu>
			<ImageArea>
				{currentlyChattingWithUser.image ? (
					<ProfileImage src={currentlyChattingWithUser.image} />
				) : (
					// {/* prettier-ignore */}
					<FirstLetter>
						{currentlyChattingWithUser.name && currentlyChattingWithUser.name.charAt(0)}
					</FirstLetter>
				)}
				<ActiveDisplay active={currentlyChattingWithUser.online} />
			</ImageArea>
			<div>
				<UserName>{currentlyChattingWithUser.name}</UserName>
				<LastSeen>
					last seen <Moment fromNow>{currentlyChattingWithUser.last_active}</Moment>
				</LastSeen>
			</div>
		</Header>
	);
};

const Header = styled.div`
	width: 100%;
	height: 45px;
	min-height: 30px;
	position: relative;
	z-index: 1;
	background: ${(props) => props.theme.white};
	padding: 8px 20px;
	min-height: 70px;
	display: flex;
	align-items: center;
	border-bottom: 3px solid #e5e5ea;
`;

const BurgerMenu = styled.div`
	display: none;

	@media screen and (max-width: 960px) {
		display: flex;
		padding: 8px 8px 8px 0;
		margin-right: 8px;
		cursor: pointer;
	}
`;

const ImageArea = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	background: #2b6cff;
	margin-right: 10px;
	border-radius: 50%;
	position: relative;
`;

const ProfileImage = styled.img`
	height: 100%;
	width: 100%;
	border-radius: 50%;
`;

const UserName = styled.h5`
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.17541px;
	margin: 0 0 2px 0;
	color: ${(props) => props.theme.black};
`;

const FirstLetter = styled.h1`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	text-align: center;
	margin: 0;
	color: ${(props) => props.theme.white};
`;

const LastSeen = styled.h5`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0px;
	margin: 0;
	color: ${(props) => props.theme.text_grey};
`;

const ActiveDisplay = styled.div`
	width: 12px;
	height: 12px;
	background-color: ${(props) => (props.active ? '#29ae60' : props.theme.text_grey)};
	border-radius: 50%;
	border: ${(props) => `1px solid ${props.theme.white}`};
	position: absolute;
	left: 74%;
	right: 4%;
	top: 72%;
	bottom: 6%;
`;
export default CustomChannelHeader;
