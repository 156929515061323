import React, { useContext } from "react";

import styled from "styled-components";
import { RiBookLine } from "react-icons/ri";
import { AiOutlinePlayCircle, AiOutlineDashboard } from "react-icons/ai";
import { FiFileText, FiUser, FiLogOut } from "react-icons/fi";

import { getFromStorage, logo, logo2, removeItemsFromStorage } from "../assets";

import { Link, useLocation, useSearchParams } from "react-router-dom";

import { AppContext } from "../context/AppContext";
import OutsideClickHandler from "react-outside-click-handler";

const SideNav = () => {
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const { tab, isSideNavVisible, currentUser, setIsSideNavVisible, school } = useContext(AppContext);

	const logOut = () => {
		removeItemsFromStorage(["subjects", "studentUser", "currentTerm", "terms", "token"]);
		window.location.reload();
	};

	const startMeeting = () => {
		window.open(`https://meet.classcube.online/${getFromStorage("schoolCode")}`);
	};

	if (searchParams.has("token") && location.pathname === "/") return <div></div>;

	return (
		<OutsideClickHandler
			onOutsideClick={() => {
				setIsSideNavVisible(true);
			}}
		>
			<Container isSideNavVisible={isSideNavVisible}>
				<Link to="/">
					<SideNavHeader>
						<Logo isSideNavVisible={isSideNavVisible} src={school?.logo || (!isSideNavVisible ? logo : logo2)} alt="ClassCube Logo" />
					</SideNavHeader>
				</Link>
				<Profile isSideNavVisible={isSideNavVisible}>
					<ImageContainer isSideNavVisible={isSideNavVisible}>
						<ProfileImage src={currentUser?.image} alt={`${currentUser?.firstname} ${currentUser?.surname}`} />
					</ImageContainer>
					<ProfileName isSideNavVisible={isSideNavVisible}>
						<LastName>{currentUser?.surname} </LastName>
						<Name>{currentUser?.firstname}</Name>
					</ProfileName>
					<ProfileDescription isSideNavVisible={isSideNavVisible}>ClassCube Student</ProfileDescription>
				</Profile>
				<NavUnlisted>
					<CustomLink to="/" style={{ color: "inherit", textDecoration: "inherit" }}>
						<SideNavTab isSideNavVisible={isSideNavVisible} active={tab === "/" ? true : false}>
							<AiOutlineDashboard
								size={24}
								style={{
									marginRight: !isSideNavVisible ? 12 : 0,
								}}
							/>
							<LinkName isSideNavVisible={isSideNavVisible}>Dashboard</LinkName>
						</SideNavTab>
					</CustomLink>
					<CustomLink to="/lessons" style={{ color: "inherit", textDecoration: "inherit" }}>
						<SideNavTab isSideNavVisible={isSideNavVisible} active={tab === "/lessons" ? true : false}>
							<RiBookLine
								size={24}
								style={{
									marginRight: !isSideNavVisible ? 12 : 0,
								}}
							/>
							<LinkName isSideNavVisible={isSideNavVisible}>Lessons</LinkName>
						</SideNavTab>
					</CustomLink>
					<ExternalCustomLink onClick={startMeeting} target="_blank" style={{ color: "inherit", textDecoration: "inherit" }}>
						<SideNavTab isSideNavVisible={isSideNavVisible} active={tab === "/live-class" ? true : false}>
							<AiOutlinePlayCircle
								size={24}
								style={{
									marginRight: !isSideNavVisible ? 12 : 0,
								}}
							/>
							<LinkName isSideNavVisible={isSideNavVisible}>Live Class</LinkName>
						</SideNavTab>
					</ExternalCustomLink>
					<CustomLink to="/assignment" style={{ color: "inherit", textDecoration: "inherit" }}>
						<SideNavTab isSideNavVisible={isSideNavVisible} active={tab === "/assignment" ? true : false}>
							<FiFileText
								size={24}
								style={{
									marginRight: !isSideNavVisible ? 12 : 0,
								}}
							/>
							<LinkName isSideNavVisible={isSideNavVisible}>Assignment</LinkName>
						</SideNavTab>
					</CustomLink>
					<CustomLink to="/profile" style={{ color: "inherit", textDecoration: "inherit" }}>
						<SideNavTab isSideNavVisible={isSideNavVisible} active={tab === "/profile" ? true : false}>
							<FiUser
								size={24}
								style={{
									marginRight: !isSideNavVisible ? 12 : 0,
								}}
							/>
							<LinkName isSideNavVisible={isSideNavVisible}>Profile</LinkName>
						</SideNavTab>
					</CustomLink>
					<SideNavTab isSideNavVisible={isSideNavVisible} active={false} style={{ cursor: "pointer" }} onClick={() => logOut()}>
						<FiLogOut size={24} style={{ marginRight: !isSideNavVisible ? 12 : 0 }} />
						<LinkName isSideNavVisible={isSideNavVisible}>LogOut</LinkName>
					</SideNavTab>
				</NavUnlisted>
			</Container>
		</OutsideClickHandler>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 275px;
	top: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	background-color: #fff;
	align-items: center;
	transition: 0.5s;
	overflow: scroll;

	@media only screen and (max-width: 1200px) {
		margin-left: ${(props) => (!props.isSideNavVisible ? "0px" : "-275px")};
		z-index: 5;
	}

	@media only screen and (min-width: 1201px) {
		width: ${(props) => (!props.isSideNavVisible ? "275px" : "100px")};
		z-index: 5;
	}
`;

const SideNavHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 25px 0 35px;
`;

const Logo = styled.img`
	width: 180px;
	max-height: 125px;
	object-fit: contain;
	transition: 0.5s;

	@media only screen and (min-width: 1201px) {
		width: ${(props) => (!props.isSideNavVisible ? "180px" : "70px")};
		max-height: 60px;
	}
`;

const CustomLink = styled(Link)`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ExternalCustomLink = styled.button`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: white;
`;

const SideNavTab = styled.div`
	background-color: ${(props) => (props.active ? props.theme.primary : props.theme.white)};
	display: flex;
	align-items: center;
	justify-content: ${(props) => (!props.isSideNavVisible ? "flex-start" : "center")};
	width: ${(props) => (!props.isSideNavVisible ? "211px" : "70%")};
	height: 35px;
	color: ${(props) => (props.active ? props.theme.white : props.theme.text_grey)};
	padding: 25px 15px;
	border-radius: 7px;
`;

const NavUnlisted = styled.ul`
	text-decoration: none;
	width: 100%;
	padding: 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LinkName = styled.p`
	margin: 0px;
	margin-left: 15px;

	@media only screen and (min-width: 1201px) {
		display: ${(props) => (!props.isSideNavVisible ? "block" : "none")};
	}
`;

const Profile = styled.div`
	margin-bottom: 30px;
	transition: 0.5s;
	border: 1px solid #d8d9da;
	padding: 10px 20px;
	border-radius: 8px;
	max-width: 75%;
	white-space: pre-wrap;
	word-wrap: break-word;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (min-width: 1201px) {
		height: ${(props) => (!props.isSideNavVisible ? "auto" : "0")};
		min-height: ${(props) => (!props.isSideNavVisible ? "270px" : "auto")};
		border: ${(props) => (!props.isSideNavVisible ? "1px solid #d8d9da" : "none")};
	}
`;

const ImageContainer = styled.div`
	width: 125px;
	max-height: 125px;

	@media only screen and (min-width: 1201px) {
		display: ${(props) => (!props.isSideNavVisible ? "block" : "none")};
	}
`;

const ProfileImage = styled.img`
	width: 100%;
	border-radius: 10px;
	aspect-ratio: 1;
	object-fit: contain;
`;

const ProfileName = styled.div`
	margin-top: 10px;

	@media only screen and (min-width: 1201px) {
		display: ${(props) => (!props.isSideNavVisible ? "block" : "none")};
	}
`;

const Name = styled.h1`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	margin: 0;
	margin-top: 10px;
	padding: 5px 0;
	display: inline;
	color: ${(props) => props.theme.grey};
`;

const LastName = styled(Name)`
	text-transform: uppercase;
`;

const ProfileDescription = styled.h1`
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	margin: 0 0 1px;
	width: 100%;
	color: ${(props) => props.theme.text_grey};

	@media only screen and (min-width: 1201px) {
		display: ${(props) => (!props.isSideNavVisible ? "block" : "none")};
	}
`;

export default SideNav;
