import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { AiOutlinePlus } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";

import { ChannelList, useChatContext } from "stream-chat-react";
import { NewChannelPreview, ChannelPreview, FormInput } from ".";
import { search as searchIcon } from "../assets";

const CustomChannelList = ({ setShowModal, showModal }) => {
	return (
		<ChannelList
			List={(listProps) => (
				<CustomChannelListContainer
					{...listProps}
					setShowModal={setShowModal}
					showModal={showModal}
				/>
			)}
			Preview={(previewProps) => <ChannelPreview {...previewProps} />}
		/>
	);
};

const CustomChannelListContainer = ({
	loading,
	children,
	LoadingIndicator,
	setShowModal,
	showModal,
}) => {
	const { client } = useChatContext();

	const [queriedUsers, setQueriedUsers] = useState([]);
	const [modalSearch, setModalSearch] = useState("");
	const [userQueryLoading, setUserQueryLoading] = useState(false);

	useEffect(() => {
		const fetchUsers = async (query) => {
			setUserQueryLoading(true);
			var queryObject = { role: "user" };

			// check if query (modalSearch) is an empty string
			// if yes (empty string) do nothing to query objects
			// if no add a key to the queryObject to filter based on the value of the search input
			if (query.length > 0) {
				queryObject["name"] = { $autocomplete: query };
			}

			// run query with queryObject and set state with result
			await client.queryUsers(queryObject).then((response) => {
				setQueriedUsers(
					// set only users who arent the currently logged in user
					response.users.filter((user) => {
						return user.id !== client.userID;
					})
				);
			});
			setUserQueryLoading(false);
		};

		// call fetchUsers with value of search (modal search)
		fetchUsers(modalSearch);
	}, [client, modalSearch]);

	return (
		<ChannelListContainer>
			<Container>
				{loading ? (
					<LoadingIndicator />
				) : (
					<>
						<Modal showModal={showModal}>
							<Header modal={true}>
								<Title>Select User</Title>
								<RiCloseLine
									size={24}
									onClick={() => setShowModal((prevState) => !prevState)}
									className="icon-hover-effect"
								/>
							</Header>
							<FormInput
								icon={searchIcon}
								placeholder="Search"
								type="text"
								value={modalSearch}
								handleChange={(e) => setModalSearch(e.target.value)}
								name="search"
								otherInputStyles={{
									width: "100%",
									marginRight: 0,
									backgroundColor: "inherit",
								}}
								otherDivStyles={{ marginTop: 10 }}
								autoComplete="off"
							/>
							{userQueryLoading ? (
								<LoadingIndicator />
							) : queriedUsers.length > 0 ? (
								queriedUsers.map((user) => (
									<NewChannelPreview
										user={user}
										setShowModal={setShowModal}
										key={user.id}
									/>
								))
							) : (
								<NoResults>No results....</NoResults>
							)}
						</Modal>
						<div style={{ height: "100%" }}>
							<Header>
								<AiOutlinePlus
									size={20}
									onClick={() => setShowModal((prevState) => !prevState)}
									className="icon-hover-effect"
								/>
							</Header>
							{children}
						</div>
					</>
				)}
			</Container>
		</ChannelListContainer>
	);
};

const Container = styled.div`
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	overflow-x: scroll;
	position: relative;
	padding: 20px 10px;

	.str-chat {
		height: max-content;
	}

	.channel-list {
		width: 100%;
		&__message {
			color: ${(props) => props.theme.white};
		}
	}

	.str-chat__channel-preview-messenger {
		background: ${(props) => props.theme.white};
		margin: 12px 0;
		border-radius: 10px;
		width: 90%;
	}

	.str-chat__channel-preview-messenger--active {
		background: ${(props) => props.theme.secondary};
	}
`;

const ChannelListContainer = styled.div`
	height: 100%;
	width: 100%;
`;

const Modal = styled.div`
	height: 100%;
	width: 100%;
	background-color: ${(props) => props.theme.background};
	padding: 20px 10px;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	transition: 2s ease-in;
	transform: translateX(${(props) => (props.showModal ? "0" : "-150%")});
`;

const Title = styled.h3`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 29px;
	margin: 0;
	padding: 0;
	color: ${(props) => props.theme.text_grey};
`;

const Header = styled.div`
	display: flex;
	justify-content: ${(props) => (props.modal ? "space-between" : "end")};
	padding: ${(props) => (props.modal ? "" : "4px 0 4px 0px")};
	align-items: center;
	position: relative;
`;

const NoResults = styled.h1`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 29px;
	margin: 10px 0 0 0;
	padding: 0;
	color: ${(props) => props.theme.text_grey};
`;

export default CustomChannelList;
