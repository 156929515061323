import { convertToRaw, EditorState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "mathquill-node/lib/mathquill.css";
import MathQuillEditor from "react-mq-editor";
import MathJaxOption from "./MathJaxOption";
import { fileHref } from "../assets";

const CustomEditor = ({ values, setFieldValue, name }) => {
	const [isMathJaxAdded, setisMathJaxAdded] = useState(false);
	const [latex, setLatex] = useState("");

	useEffect(() => {
		let string = draftToHtml(convertToRaw(values.getCurrentContent()));
		if (values.getCurrentContent().hasText() && string.indexOf("\\(") !== -1 && string.indexOf("\\)") !== -1) {
			let start = string.indexOf("\\(");
			let end = string.indexOf("\\)");

			setLatex(string.substring(start + 2, end));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function insertCharacter(characterToInsert, editorState) {
		const currentContent = editorState.getCurrentContent(),
			currentSelection = editorState.getSelection();

		const newContent = Modifier.replaceText(currentContent, currentSelection, characterToInsert);

		const newEditorState = EditorState.push(editorState, newContent, "insert-characters");

		setLatex("");

		return setFieldValue(name, EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()));
	}

	return (
		<>
			<div className={`editor-tooltip ${isMathJaxAdded ? "" : "d-none"}`}>
				<MathQuillEditor
					latex={latex}
					config={{
						handlers: {
							edit: (mathField) => {
								setLatex(mathField.latex());
							},
						},
					}}
				/>
				<div className="col-12 d-flex justify-content-between align-items-end p-0">
					<a
						href={`${fileHref}symbols.pdf`}
						className="sample-download mb-1"
						target="_blank"
						rel="noreferrer"
					>
						View list of all mathematical symbols and equations
					</a>
					<button
						className="pri-action-btn"
						type="button"
						onClick={() => {
							insertCharacter("\\(".concat(latex, "\\)"), values);
						}}
					>
						Insert formula
					</button>
				</div>
			</div>
			<Editor
				editorState={values}
				onEditorStateChange={(editorState) => setFieldValue(name, editorState)}
				toolbarCustomButtons={[<MathJaxOption setisMathJaxAdded={setisMathJaxAdded} />]}
			/>
		</>
	);
};

export default CustomEditor;
