/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { EditorState } from "draft-js";
import * as yup from "yup";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../assets/css/editor.css";

import { AssignmentDropzone, CustomEditor } from "./";
import { FormButton, Loader } from ".";
import { getFileName, getFromStorage, makePostAPICall, returnAttachmentImage } from "../assets";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Swal from "sweetalert2";
import { useAppContext } from "../context/AppContext";
import { Formik } from "formik";
import { makeGetAPICall } from "../assets/functions";
import moment from "moment";
import { MathJax } from "better-react-mathjax";

const LessonAssignments = ({ assignmentRef, assignment }) => {
	const { currentUser } = useAppContext();
	let editorState = EditorState.createEmpty();

	const [assignmentData, setAssignmentData] = useState(null);
	const [submission, setSubmission] = useState(null);

	useEffect(() => {
		if (Array.isArray(assignment) || !assignment) {
			setAssignmentData({});
		} else {
			setAssignmentData(Object.values(assignment)[0]);
			makeGetAPICall(
				{
					action: "get_assginment_submission",
					school_code: getFromStorage("schoolCode"),
					assignment_id: Object.values(assignment)[0]["id"],
					student_id: currentUser?.id,
				},
				(response) => {
					if (response.data) {
						setSubmission(response.data);
					} else {
						setSubmission("No submission");
					}
				},
				(error) => {
					Swal.fire({
						icon: "error",
						toast: true,
						position: "top-end",
						title: error.msg,
						showConfirmButton: false,
						timer: 2500,
					});
				}
			);
		}
	}, [assignment]);

	const validationSchema = yup.object().shape({});
	const initialValues = {
		action: "submit_assignment",
		student_id: currentUser?.id,
		assignment_id: Array.isArray(assignment) || !assignment ? "" : Object.values(assignment)[0]["id"],
		school_code: getFromStorage("schoolCode"),
		response: editorState,
		doc: [],
	};

	if (!assignmentData || !Object.keys(assignmentData).length)
		return (
			<Container>
				<None>No assignments yet</None>
			</Container>
		);

	return (
		<MathJax>
			<Container ref={assignmentRef}>
				<Row>
					<Heading>Assignment</Heading>
					<span>Due Date: {moment(assignmentData?.dueDate).format("Do MMM YYYY")}</span>
				</Row>
				<hr />

				{/* assignment questions */}
				<Question
					dangerouslySetInnerHTML={{
						__html: assignmentData?.content,
					}}
				/>

				{/* submission form  */}
				{!moment(assignmentData?.dueDate).isSameOrBefore() && !submission?.grade ? (
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							const data = { ...values };
							data["response"] = draftToHtml(convertToRaw(data.response.getCurrentContent()));
							data["doc"] = data.doc[0];

							makePostAPICall(
								data,
								() => {
									Swal.fire({
										icon: "success",
										toast: true,
										position: "top-end",
										title: "Assignment completed",
										showConfirmButton: false,
										timer: 1500,
									}).then(() => window.location.reload());
								},
								(error) => {
									Swal.fire({
										icon: "error",
										toast: true,
										position: "top-end",
										title: error.msg,
										showConfirmButton: false,
										timer: 2500,
									});
								}
							);
						}}
					>
						{({ values, handleSubmit, setFieldValue, isSubmitting }) => (
							<form style={{ marginBottom: 20 }} onSubmit={handleSubmit}>
								<CustomEditor values={values.response} setFieldValue={setFieldValue} name="response" />
								<AssignmentDropzone setFieldValue={setFieldValue} files={values.doc} />

								<div className="d-flex justify-content-end">
									<FormButton text={isSubmitting ? <Loader /> : "Submit"} type="submit" disabled={isSubmitting} />
								</div>
							</form>
						)}
					</Formik>
				) : null}

				{/* submission info (only when student has submitted before) */}
				{submission !== null || submission !== "No submission" ? (
					<>
						<Heading>Submission</Heading>
						<hr />
						<Grade>Grade: {submission?.grade ? `${submission.grade}%` : "Not yet graded"}</Grade>
						{submission?.reply && (
							<Div>
								<Section>Reply</Section>
								<div
									className="notes-area"
									dangerouslySetInnerHTML={{
										__html: submission?.reply,
									}}
								/>
							</Div>
						)}
						{submission?.attachment && (
							<>
								<Section>Attachments</Section>
								<a href={`${process.env.REACT_APP_BUCKET_BASEURL}${submission?.attachment}`} className="attachment-link">
									<div className="attachment">
										{returnAttachmentImage(submission?.attachment)}
										{getFileName(submission?.attachment)}
									</div>
								</a>
							</>
						)}
					</>
				) : null}
			</Container>
		</MathJax>
	);
};

const Container = styled.div`
	padding: 20px 100px;

	@media only screen and (max-width: 1024px) {
		padding: 20px 30px;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	@media only screen and (max-width: 425px) {
		flex-direction: column;
		align-items: initial;
	}
`;

const Heading = styled.h1`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	text-transform: uppercase;
	margin-top: 50px;

	@media only screen and (max-width: 1024px) {
		margin-top: 20px;
	}
`;

const Div = styled.div`
	.notes-area {
		background-color: white;
		padding: 20px;
		width: 100%;
		min-height: 100%;

		h1 {
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 31px;

			color: #47505b;
		}

		h2 {
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 27px;

			color: #47505b;
		}

		h3 {
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 25px;

			color: #47505b;
		}

		h4 {
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 23px;

			color: #47505b;
		}

		p {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;

			color: #5e6c85;
		}

		li {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;

			color: #5e6c85;
		}

		img {
			max-width: 100%;
			width: 50%;
			resize: both;
		}
	}
`;

const Question = styled.div`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 164.52%;
	color: ${(props) => props.theme.grey};
	margin: 0;
	margin-bottom: 30px;

	h1 {
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 31px;

		color: #47505b;
	}

	h2 {
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 27px;

		color: #47505b;
	}

	h3 {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;

		color: #47505b;
	}

	h4 {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 23px;

		color: #47505b;
	}

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;

		color: #47505b;
	}

	li {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;

		color: #47505b;
	}
`;

const None = styled.h2`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 17px;
	margin-bottom: 3px;
	color: ${(props) => props.theme.primary};
`;

const Grade = styled.h5`
	text-align: right;
`;

const Section = styled.p`
	font-size: 18px;
	margin-bottom: 5px;
	font-weight: bold;
`;

export default LessonAssignments;
