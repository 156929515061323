import React, { useEffect, useState } from "react";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { useSearchParams } from "react-router-dom";

const CustomPagination = ({ rowsPerPage, rowCount, onChangePage, currentPage }) => {
	const [, setSearchParams] = useSearchParams();

	const [pagenateInput, setPagenateInput] = useState(currentPage);

	useEffect(() => {
		if (currentPage === 1) {
			setSearchParams();
			return;
		}
		setSearchParams({ page: currentPage });
	}, [currentPage, setSearchParams]);

	function getNumberOfPages(rowCount, rowsPerPage) {
		return Math.ceil(rowCount / rowsPerPage);
	}

	const pages = getNumberOfPages(rowCount, rowsPerPage);
	const nextDisabled = currentPage === pages;
	const previousDisabled = currentPage === 1;

	const handleBackButtonClick = () => {
		if (currentPage === 1) return;
		onChangePage(currentPage - 1);
		setPagenateInput((prevState) => parseInt(prevState) - 1);
	};

	const handleNextButtonClick = () => {
		if (currentPage === pages) return;
		onChangePage(currentPage + 1);
		setPagenateInput((prevState) => parseInt(prevState) + 1);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			if (parseInt(pagenateInput) < 1 || parseInt(pagenateInput) > pages) {
				return;
			}
			onChangePage(parseInt(pagenateInput));
		}
	};

	if (pages <= 1) return null;

	return (
		<div className="exam-pagenate">
			<button
				className={previousDisabled ? "pagenate-prev my-4" : "pagenate-prev pagenate-active my-4"}
				onClick={() => handleBackButtonClick()}
			>
				<IoMdArrowDropleft size={20} />
			</button>
			<input
				type="text"
				name="pagenate-input"
				className="pagenate-input my-4"
				value={pagenateInput}
				onChange={(e) => setPagenateInput(e.target.value)}
				onKeyDown={(e) => handleKeyDown(e)}
			/>
			<p className="pagenate-text my-4" id="pagenate-num">
				{" "}
				of {pages}
			</p>
			<button
				className={nextDisabled ? "pagenate-next my-4" : "pagenate-next pagenate-active my-4"}
				onClick={() => handleNextButtonClick()}
			>
				<IoMdArrowDropright size={20} />
			</button>
		</div>
	);
};

export default CustomPagination;
