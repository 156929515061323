import React from "react";
import styled from "styled-components";
import { SubjectTile, Loader } from ".";

const RecentUploads = ({ data }) => {
	if (!data) return <Loader />;
	return (
		<Container className="flex-wrap">
			{data.length ? (
				data.map((subject, index) => (
					<SubjectTile key={index} subject={subject} index={index} />
				))
			) : (
				<div className="d-flex col-12 align-items-center justify-content-center">
					<H4>No recent lessons</H4>
				</div>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 2%;

	@media only screen and (max-width: 1024px) {
		gap: 2%;
	}

	@media only screen and (max-width: 600px) {
		gap: 10px;
	}
`;

const H4 = styled.h4`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	color: #4a4a4a;
`;

export default RecentUploads;
