import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../context/AppContext";

const LessonTile = ({ subject, index }) => {
	const { school } = useAppContext();

	return (
		<LessonContainer>
			<StyledLink to={`/lessons/${subject.subject_id}`}>
				<LessonImage index={index} randomize={!!school?.options} />
				<LessonDescription>
					<div className="d-flex flex-column justify-content-center">
						<LessonName>{subject?.subject}</LessonName>
						<LessonNumber>{subject?.lessons} Lessons</LessonNumber>
					</div>
					<LessonStatus>Start</LessonStatus>
				</LessonDescription>
			</StyledLink>
		</LessonContainer>
	);
};

const LessonContainer = styled.div`
	width: 23.5%;
	background-color: #fff;
	border-radius: 10px;
	margin-bottom: 25px;
	overflow: hidden;
	transition: all 0.5s ease-in-out;

	@media only screen and (max-width: 1024px) {
		width: 47%;
	}

	@media only screen and (max-width: 600px) {
		width: 100%;
		margin-bottom: 30px;
	}

	&:hover {
		transform: scale(1.05);
	}

	&:hover h2,
	&:hover p {
		color: ${(props) => props.theme.primary};
		cursor: pointer;
	}
`;

const LessonImage = styled.div`
	width: 100%;
	aspect-ratio: 1/0.6;
	background-color: ${(props) => {
		const COLORS = ["#F3464A", "#67BB93", "#4699F7", "#4F5D7B", "#F6CC6A"];
		var schoolcolors = [props.theme.primary, props.theme.secondary];

		return props.randomize
			? schoolcolors[props.index % 2]
			: COLORS[props.index % COLORS.length];
	}};
`;

const LessonName = styled.p`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 15.292px;
	line-height: 19px;
	letter-spacing: 0.149021px;
	margin: 0 0 2px;

	color: ${(props) => props.theme.grey};
`;

const LessonNumber = styled.p`
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.17541px;
	margin: 0;

	color: ${(props) => props.theme.text_grey};
`;

const LessonStatus = styled.h2`
	font-family: Inter;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.17541px;
	text-transform: capitalize;

	color: #4caf5c;
`;

const LessonDescription = styled.div`
	padding: 15px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledLink = styled(Link)`
	color: inherit;
	text-decoration: inherit;
`;

export default LessonTile;
