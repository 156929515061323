import React from "react";
import styled from "styled-components";
import { returnAttachmentImage } from "../assets";

const Preview = ({ file }) => {
	if (file.type.includes("image")) {
		return (
			<Thumb key={file.name}>
				<ThumbImage src={file.preview} />
				<ThumbName>{file.name}</ThumbName>
			</Thumb>
		);
	} else if (file.type === "application/pdf") {
		return (
			<Thumb key={file.name}>
				{returnAttachmentImage("a.pdf")}
				<ThumbName>{file.name}</ThumbName>
			</Thumb>
		);
	} else if (file.type.includes("wordprocessingml")) {
		return (
			<Thumb key={file.name}>
				{returnAttachmentImage("a.docx")}
				<ThumbName>{file.name}</ThumbName>
			</Thumb>
		);
	} else if (file.type.includes("spreadsheetml")) {
		return (
			<Thumb key={file.name}>
				{returnAttachmentImage("a.xlsx")}
				<ThumbName>{file.name}</ThumbName>
			</Thumb>
		);
	} else if (file.type.includes("presentationml")) {
		return (
			<Thumb key={file.name}>
				{returnAttachmentImage("a.pptx")}
				<ThumbName>{file.name}</ThumbName>
			</Thumb>
		);
	} else return <div />;
};

export const Thumb = styled.div`
	display: flex;
	/* align-items: center; */
	position: relative;
`;

export const ThumbImage = styled.img`
	display: block;
	width: 30px;
	height: 30px;
	aspect-ratio: 1;
	border-radius: 10px;
`;

export const ThumbName = styled.p`
	margin: 0;
`;

export default Preview;
