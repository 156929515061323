import React from "react";
import DataTable from "react-data-table-component";
import { useSearchParams } from "react-router-dom";
import { customStyles } from "../assets";
import CustomPagination from "./CustomPagination";

const CustomDataTable = ({ columns, data, style, ...props }) => {
	let [searchParams] = useSearchParams();
	return (
		<DataTable
			columns={columns}
			data={data}
			pagination
			paginationDefaultPage={searchParams.get("page") ? parseInt(searchParams.get("page")) : 1}
			paginationComponent={CustomPagination}
			customStyles={style || customStyles}
			{...props}
		/>
	);
};

export default CustomDataTable;
