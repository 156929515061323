import React from "react";
import { useChatContext } from "stream-chat-react";
import styled from "styled-components";
import { filterObject } from "../assets";

const ChannelPreview = (previewProps) => {
	const { channel, setActiveChannel, displayImage, displayTitle, unread } =
		previewProps;

	const { channel: activeChannel, client } = useChatContext();

	// check if current client is in members object
	if (!channel?.state?.members.hasOwnProperty(client.userID)) {
		return null;
	}

	// get the object with the other user data from the object of users in this chat
	const otherUser = filterObject(
		channel.state.members,
		"user_id",
		client.userID
	);

	// check if other user is online
	const isOtherUserOnline = otherUser[Object.keys(otherUser)[0]].user.online;

	// check if this chat is active
	const isActive = channel?.id === activeChannel?.id;

	// function to obtain the last message in the chat
	const renderMessageText = () => {
		if (channel.state.messages.length === 0) return "Nothing yet....";
		const lastMessageText =
			channel.state.messages[channel.state.messages.length - 1].text;

		const text = lastMessageText || "message text";

		return text.length < 60 ? lastMessageText : `${text.slice(0, 20)}...`;
	};

	return (
		<Container isActive={isActive} onClick={() => setActiveChannel(channel)}>
			<ImageArea>
				{displayImage ? (
					<ProfileImage src={displayImage} />
				) : (
					<FirstLetter>{displayTitle && displayTitle.charAt(0)}</FirstLetter>
				)}
				<ActiveDisplay active={isOtherUserOnline} />
			</ImageArea>
			<div>
				<UserName>{displayTitle}</UserName>
				<LastMessage>{renderMessageText()}</LastMessage>
			</div>
			<OtherInfo>
				<LastMessageTime>12:32</LastMessageTime>
				{unread ? (
					<UnReadNumberCircle unread={unread}>
						<UnReadNumber>{unread}</UnReadNumber>
					</UnReadNumberCircle>
				) : (
					<UnReadNumberCircle unread={unread} />
				)}
			</OtherInfo>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	width: 90%;
	border: none;
	padding: 8px 20px;
	align-items: center;
	text-align: left;
	margin: 8px 0;
	color: ${(props) => props.theme.black};
	background-color: ${(props) =>
		props.isActive ? props.theme.secondary : props.theme.white};
	margin: 12px 0;
	border-radius: 10px;

	&:hover {
		cursor: pointer;
	}
`;

const ImageArea = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	background: #2b6cff;
	margin-right: 10px;
	border-radius: 50%;
	position: relative;
`;

const ProfileImage = styled.img`
	height: 100%;
	width: 100%;
	border-radius: 50%;
`;

const UserName = styled.h5`
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.17541px;
	margin: 0 0 2px 0;
	color: ${(props) => props.theme.black};
`;

const LastMessage = styled.h5`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.17541px;
	margin: 0 0 2px 0;
	color: ${(props) => props.theme.text_grey};
`;

const LastMessageTime = styled.h5`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;

	color: ${(props) => props.theme.text_grey};
`;

const FirstLetter = styled.h1`
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	text-align: center;
	margin: 0;
	color: ${(props) => props.theme.white};
`;

const ActiveDisplay = styled.div`
	width: 12px;
	height: 12px;
	background-color: ${(props) =>
		props.active ? "#29ae60" : props.theme.text_grey};
	border-radius: 50%;
	border: ${(props) => `1px solid ${props.theme.white}`};
	position: absolute;
	left: 74%;
	right: 4%;
	top: 72%;
	bottom: 6%;
`;

const UnReadNumberCircle = styled.div`
	background-color: ${(props) =>
		props.unread ? props.theme.primary : "inherit"};
	border-radius: 50%;
	height: 14px;
	width: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const UnReadNumber = styled.div`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 8.49557px;
	line-height: 10px;
	letter-spacing: 0.149021px;

	color: ${(props) => props.theme.white};
`;

const OtherInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
`;

export default ChannelPreview;
