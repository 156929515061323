import React from "react";
import styled, { keyframes } from "styled-components";

const Loader = ({ className, ...rest }) => {
	return (
		<div className={`d-flex align-items-center justify-content-center ${className}`} {...rest}>
			<Spinner />
		</div>
	);
};

const btnLoader = keyframes`
  from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
`;

const Spinner = styled.span`
	width: 25px;
	height: 25px;
	display: inline-block;
	padding: 0px;
	border-radius: 100%;
	border: 3px solid;
	border-top-color: var(--primary-color);
	border-bottom-color: white;
	border-left-color: var(--primary-color);
	border-right-color: white;
	-webkit-animation: ${btnLoader} 0.8s linear infinite;
	animation: ${btnLoader} 0.8s linear infinite;
`;

export default Loader;
