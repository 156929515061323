import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";

import { AppContext } from "../context/AppContext";
import Overlay from "react-overlays/Overlay";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { getFromStorage, removeItemsFromStorage, setToStorage } from "../assets";

const TopNav = () => {
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const { currentUser, terms, school } = useContext(AppContext);

	const [show, setShow] = useState(false);
	const triggerRef = useRef(null);
	const containerRef = useRef(null);

	const logOut = () => {
		removeItemsFromStorage(["subjects", "studentUser", "currentTerm", "terms", "token"]);
		window.location.reload();
	};

	if (searchParams.has("token") && location.pathname === "/") return <div></div>;

	return (
		<Container ref={containerRef}>
			<Welcome>{school?.name}</Welcome>
			<Select
				name="drinks"
				required
				value={getFromStorage("currentTerm")}
				onChange={(event) => {
					setToStorage("currentTerm", event.target.value);
					window.location.reload();
				}}
			>
				<option value="" disabled hidden>
					Academic Year/Term
				</option>
				{terms?.map((term) => (
					<option key={term.id} value={term.id} label={`${term.termSession}`} />
				))}
			</Select>
			<UserProfileImg
				src={currentUser?.image}
				alt={`${currentUser?.firstname} ${currentUser?.surname}`}
				ref={triggerRef}
				onClick={() => setShow((prevState) => !prevState)}
			/>
			<Overlay
				show={show}
				rootClose
				offset={[0, 10]}
				onHide={() => setShow(false)}
				container={containerRef}
				target={triggerRef}
			>
				{({ props, arrowProps }) => (
					<Tooltip {...props} placement="bottom">
						<Arrow {...arrowProps} style={arrowProps.style} />
						<Body>
							<Link to="/profile" style={{ color: "inherit", textDecoration: "inherit" }}>
								<Item>Your Profile</Item>
							</Link>
							<Item onClick={() => logOut()}>LogOut</Item>
						</Body>
					</Tooltip>
				)}
			</Overlay>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	padding: 5px 0px;
	margin: 10px 0px 30px;
`;

const Welcome = styled.h3`
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 47px;
	text-transform: capitalize;
	margin: 0;
	color: ${(props) => props.theme.primary};

	@media (max-width: 768px) {
		display: none;
	}
`;

const UserProfileImg = styled.img`
	border-radius: 50%;
	height: 45px;
	width: 45px;

	&:hover {
		cursor: pointer;
	}
`;

const Tooltip = styled.div`
	position: absolute;
	z-index: 5;
`;

const Arrow = styled.div`
	position: absolute;
	width: 45px;
	height: 45px;
	z-index: -1;

	&::before {
		content: "";
		position: absolute;
		transform: rotate(45deg);
		background: ${(props) => props.theme.white};
		width: 45px;
		height: 45px;
		top: 0;
		left: 0;
	}
`;

const Body = styled.div`
	padding: 3px 0;
	color: ${(props) => props.theme.black};
	text-align: center;
	border-radius: 3px;
	background-color: ${(props) => props.theme.white};
`;

const Item = styled.div`
	color: ${(props) => props.theme.grey};
	padding: 10px 15px;
	text-align: left;

	&:hover {
		background-color: ${(props) => props.theme.primary};
		color: ${(props) => props.theme.white};
		cursor: pointer;
	}
`;

const Select = styled.select`
	padding: 10px 15px;
	background-color: ${(props) => props.theme.secondary};
	border: none;
	display: block;
	margin-bottom: 3px;
	margin-left: auto;
	border-radius: 10px;
	margin-right: 15px;

	&:hover {
		outline: none;
		border: none;
	}
`;

export default TopNav;
