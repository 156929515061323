import React from 'react';
import styled from 'styled-components';

const Section = ({ title, children }) => {
	return (
		<Container>
			<SectionTitle>{title}</SectionTitle>
			{children}
		</Container>
	);
};

const SectionTitle = styled.h2`
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 36px;
	color: ${(props) => props.theme.grey};
	margin: 0 0 25px;

	@media only screen and (max-width: 1500px) {
		font-size: 25px;
		line-height: 30px;
	}
`;

const Container = styled.div`margin: 0 0 70px;`;

export default Section;
