import React from "react";
const Dashboard = React.lazy(() => import("./Dashboard"));
const Lessons = React.lazy(() => import("./Lessons"));
const LiveClass = React.lazy(() => import("./LiveClass"));
const Profile = React.lazy(() => import("./Profile"));
const Messages = React.lazy(() => import("./Messages"));
const Assignment = React.lazy(() => import("./Assignment"));
const Login = React.lazy(() => import("./Login"));
const Lesson = React.lazy(() => import("./Lesson"));
const SelectLesson = React.lazy(() => import("./SelectLesson"));
const ClassCube = React.lazy(() => import("./ClassCube"));
const LoginWithCode = React.lazy(() => import("./LoginWithCode"));

export {
	Dashboard,
	Lessons,
	LiveClass,
	Profile,
	Messages,
	Assignment,
	Login,
	Lesson,
	SelectLesson,
	ClassCube,
	LoginWithCode,
};
