import React, { Suspense, useEffect, useMemo, useState } from "react";
import { AppContext } from "./context/AppContext";
import { ThemeProvider } from "styled-components";
import { Login, ClassCube, LoginWithCode } from "./pages";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getFromStorage } from "./assets";

import "./assets/css/general.css";
import { MathJaxContext } from "better-react-mathjax";
import { Loader } from "./components";

function App() {
	const theme = {
		primary: "var(--primary-color)",
		secondary: "var(--secondary-color)",
		white: "white",
		grey: "#4A4A4A",
		text_grey: "#979797",
		black: "#000",
		error: "#FF0000",
		background: "#F5F6F8",
	};

	const [token, setToken] = useState(getFromStorage("token"));
	const [tab, setTab] = useState("/");
	const [currentUser, setCurrentUser] = useState(getFromStorage("studentUser"));
	const [isSideNavVisible, setIsSideNavVisible] = useState(false);
	const [subjects, setSubjects] = useState(getFromStorage("subjects"));
	const [terms, setTerms] = useState(getFromStorage("terms"));
	const [school, setSchool] = useState(getFromStorage("school"));

	const providerValue = useMemo(
		() => ({
			tab,
			setTab,
			isSideNavVisible,
			setIsSideNavVisible,
			currentUser,
			setCurrentUser,
			token,
			setToken,
			subjects,
			setSubjects,
			terms,
			setTerms,
			school,
			setSchool,
		}),
		[
			tab,
			setTab,
			isSideNavVisible,
			setIsSideNavVisible,
			currentUser,
			setCurrentUser,
			token,
			setToken,
			subjects,
			setSubjects,
			terms,
			setTerms,
			school,
			setSchool,
		]
	);

	useEffect(() => {
		if (school?.options) {
			var r = document.querySelector(":root");
			r.style.setProperty("--primary-color", school?.options?.color || "#4699F6");
			r.style.setProperty("--secondary-color", school?.options?.accent_color || "#E8F2FF");
		}
	}, [school]);

	return (
		<Suspense
			fallback={
				<div className="exam_form">
					<Loader style={{ marginTop: 55 }} />
				</div>
			}
		>
			<MathJaxContext>
				<ThemeProvider theme={theme}>
					<AppContext.Provider value={providerValue}>
						<Router>
							<Routes>
								<Route path="/*" element={<ClassCube />} />
								<Route path="/login" element={<Login />} />
								<Route path="/login/:schoolCode" element={<LoginWithCode />} />
							</Routes>
						</Router>
					</AppContext.Provider>
				</ThemeProvider>
			</MathJaxContext>
		</Suspense>
	);
}

export default App;
