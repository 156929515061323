import React from "react";
import styled from "styled-components";
import { AnnouncementTile } from ".";

const Announcements = ({ announcements }) => {
	if (!announcements || Object.values(announcements)[0] === null)
		return (
			<Container>
				<None>No announcements yet</None>
			</Container>
		);

	return (
		<Container>
			{Object.values(announcements).map((announcement, index) => (
				<AnnouncementTile announcement={announcement} key={index} />
			))}
		</Container>
	);
};

const Container = styled.div`
	padding: 50px 100px 20px;
	min-height: 150px;

	@media only screen and (max-width: 1024px) {
		padding: 50px 30px 20px;
	}
`;

const None = styled.h2`
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 17px;
	margin-bottom: 3px;
	color: ${(props) => props.theme.primary};
`;

export default Announcements;
