import React from 'react';
import styled from 'styled-components';

const FormButton = ({ text, type, otherStyles, disabled }) => {
	return (
		<Button style={{ ...otherStyles }} type={type || 'button'} disabled={disabled || false}>
			{text}
		</Button>
	);
};

const Button = styled.button`
	border-radius: 5px;
	padding: 10px 25px;
	border: none;
	background-color: ${(props) => (props.disabled ? props.theme.grey : props.theme.primary)};
	color: ${(props) => props.theme.white};
	max-height: 45px;
`;

export default FormButton;
