import React from 'react';
import styled from 'styled-components';

const RecommendedTopics = ({ data }) => {
	return (
		<Container>
			{data.map((topic, index) => (
				<Topic key={index}>
					<TopicTitle>{topic.title}</TopicTitle>
				</Topic>
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Topic = styled.div`
	background-color: ${(props) => props.theme.white};
	display: flex;
	padding: 20px 35px;
	align-items: center;
	width: 350px;
	border-radius: 5px;
	margin-bottom: ${(props) => (props.index < 3 ? '20px' : '0')};
`;

const TopicTitle = styled.h1`
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.2px;

	&:hover {
		color: ${(props) => props.theme.primary};
	}
`;

export default RecommendedTopics;
