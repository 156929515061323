import React from 'react';
import styled from 'styled-components';

const PageTitle = ({ children }) => {
	return <Title>{children}</Title>;
};

const Title = styled.h2`
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 36px;
	margin: 0;
	color: ${(props) => props.theme.grey};
`;

export default PageTitle;
